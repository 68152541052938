<!--
 * @Author: Neko
 * @Date: 2021-01-06 13:48:00
 * @LastEditTime: 2021-03-25 13:57:17
 * @Description: 商品分组栏组件
 * @LastEditors: Neko
-->
<template>
  <div class="goods-group__container">
    <div
      v-for="(item, index) in data.componentMenuList"
      :key="item.id"
      class="goods-group__wrap"
    >
      <h4 class="title">{{ item.menuName || $t('common.inputTitle') }}</h4>

      <div v-if="((item.componentGoodsList || []).length) > (max === 8 ? 4 : 8)" class="goods-group__header">

        <a href="javascript:void(0)" class="floor__more-wrap" @click="onClickMoreBtn(index)">
          <div class="text">
            SEE <span class="bold">MORE</span>
          </div>

          <div class="icon-box">

            <div class="arrow-box">
              <i class="icon minus el-icon-minus" />
              <i class="icon right el-icon-right" />
            </div>

            <span class="circle" />
          </div>
        </a>
        <!-- <span class="title">{{ item.menuName }}</span>

        <a v-if="item.componentGoodsList.length > 8" class="link" href="javascript:void(0)" @click="onClickMoreBtn(index)">
          查看更多 <i class="icon el-icon-arrow-right" />
        </a> -->
      </div>

      <GoodsList :data="maxNumber ? item.componentGoodsList.slice(0, maxNumber) : item.componentGoodsList" />
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import GoodsList from './GoodsList'

export default {
  name: 'GoodsGroup',

  components: {
    GoodsList
  },

  props: {
    data: {
      type: Object,
      default: () => ({
        componentMenuList: []
      })
    },

    max: {
      type: Number,
      default: 0
    }
  },

  setup(props, { root }) {
    const router = root.$router

    const onClickMoreBtn = index => {
      const now = props.data.componentMenuList[index]

      router.push({ name: 'GroupList', query: { model_id: now.modelId, component_id: now.id, model_type: now.modelType }})
    }

    const maxNumber = computed(() => {
      return props.max === 8 ? 4 : 8
    })

    return {
      maxNumber,

      onClickMoreBtn
    }
  }
}
</script>

<style lang="scss" scoped>
.goods-group__container {

}

.goods-group__wrap {
  width: 1200px;
  padding: 35px 0 20px;
  margin: 0 auto;
}

.title {
  margin: 0 0 27px;
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  color: #0049AC;
}

.goods-group__header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  .floor__more-wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      .icon-box {
        .circle {
          &::after {
            width: 51px;
            height: 51px;
            border: 1px solid #4380BD;
          }
        }

        .arrow-box {
          transform: translateX(75px);
        }
      }
    }
  }

  .text {
    font-size: 12px;
    color: #4380BD;

    .bold {
      font-weight: bolder;
      color: #0049AC;
    }
  }

  .icon-box {
    position: relative;
    height: 54px;
    margin-left: 40px;

    .circle {
      position: relative;
      display: inline-block;
      width: 51px;
      height: 51px;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 51px;
        height: 51px;
        border: 1px solid #4380BD;
        border-radius: 50%;
        transition: .5s all;
        transform: translate(-50%, -50%);
      }
    }

    .arrow-box {
      position: absolute;
      line-height: 48px;

      transform: translateX(0);
      transition: .3s all;

      .minus {
        font-weight: bolder;
        transform: translate(-20px, 1.5px) scaleX(2);
      }

      .right {
        font-size: 16px;
        font-weight: bolder;
        transform: translate(-20px, 0);
      }
    }

    .icon {
      font-size: 20px;
      line-height: 50px;
      transition: .5s all;
      color: #0049AC;
    }
  }
}
</style>
