<!--
 * @Author: Neko
 * @Date: 2021-01-06 13:03:07
 * @LastEditTime: 2021-03-25 17:58:39
 * @LastEditors: Neko
-->
<template>
  <div class="title__container">
    <div class="title__wrap">
      <h2 class="title">{{ textData.title }}</h2>
      <p class="description">{{ textData.content }}</p>
    </div>

    <a v-if="textData.isOpenMore" href="javascript:void(0)" class="title__link" @click="onClickMoreBtn">
      {{ $t('home.store.see_more') }} <i class="icon el-icon-right" />
    </a>
  </div>
</template>

<script>
import { computed, toRefs } from '@vue/composition-api'
import { handleLineClick } from '@/utils'

export default {
  name: 'Title',

  props: {
    data: {
      type: Array,
      default: () => ([])
    }
  },

  setup(props, { root }) {
    // const router = root.$router

    const propsRef = toRefs(props)

    const textData = computed(() => propsRef.data.value[0] || {})

    const onClickMoreBtn = _ => {
      handleLineClick(textData.value)
    }

    return {
      textData,

      onClickMoreBtn
    }
  }
}
</script>

<style lang="scss" scoped>
.title__container {
  position: relative;
  padding: 45px 0 0px;
  margin-bottom: 20px;

  .title__wrap {
    width: 800px;
    margin: 0 auto;
    text-align: center;

    .title {
      margin-bottom: 29px;
      font-size: 24px;
      line-height: 32px;
      font-weight: normal;
      color: #0049AC;
    }

    .description {
      font-size: 12px;
      line-height: 22px;
      color: #AAAAAA;
    }
  }

  .title__link {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 14px;
    line-height: 22px;
    color: #222222;

    .icon {
      display: inline-block;
      margin-left: 8px;
      font-weight: bolder;
    }
  }
}
</style>
