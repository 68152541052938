/*
 * @Author: Neko
 * @Date: 2021-03-26 10:33:35
 * @LastEditTime: 2021-03-26 10:55:25
 * @LastEditors: Neko
 */

export default function(fn = function() {}, interval = 500) {
  let timer = null

  return function(...args) {
    if (timer) {
      window.clearTimeout(timer)
      timer = null
    }

    timer = setTimeout(() => {
      fn.call(this, ...args)
    }, interval)
  }
}
