<!--
 * @Author: Neko
 * @Date: 2020-12-31 11:56:08
 * @LastEditTime: 2021-10-15 14:26:20
 * @LastEditors: Please set LastEditors
-->
<template>
  <div class="home__container">
    <div class="home__width-container">
      <Breadcrumbs v-if="id" :data="breadcrumbList" name-label="name" value-label="value" @click="onClickBreadcrumb" />
      <template v-for="item in filteredComponent">
        <Swiper
          v-if="item.modelType === 2 && item.sonType === 1"
          ref="swipeRefs"
          :key="item.id"
          :model-type="item.modelType"
          :data="modelData[item.modelType][item.id]"
          :type="item.showMode"
        />

        <Picture
          v-if="item.modelType === 2 && item.sonType === 2"
          :key="item.id"
          :model-type="item.modelType"
          :data="modelData[item.modelType][item.id]"
          :type="item.showMode"
        />

        <Title
          v-if="item.modelType === 5 && item.sonType === 4"
          :key="item.id"
          :data="modelData[item.modelType][item.id]"
        />

        <GoodsList
          v-if="item.modelType === 6"
          :key="item.id"
          :data="modelData[item.modelType][item.id]"
        />

        <GoodsGroup
          v-if="item.modelType === 7"
          :key="item.id"
          :data="modelData[item.modelType][item.id]"
          :max="8"
        />

        <Store
          v-if="item.modelType === 11"
          :key="item.id"
          :data="modelData[item.modelType][item.id]"
          :model-type="item.modelType"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRef, toRefs, watch, computed, onMounted } from '@vue/composition-api'

import Swiper from '@/components/Home/Swiper'
import Title from '@/components/Home/Title'
import GoodsList from '@/components/Common/GoodsList'
import GoodsGroup from '@/components/Common/GoodsGroup'
import Breadcrumbs from '@/components/Layout/Breadcrumbs'
import Picture from '@/components/Home/Picture'
import Store from '@/components/Home/Store'

import { handleLineClick, getDomainPrefix } from '@/utils'
import { useRequest } from '@/utils/request.js'
import useDebounce from '@/composition/useDebounce.js'
import useCodeInLifeCycle from '@/composition/useCodeInLifeCycle.js'

export default {
  name: 'Home',

  components: {
    Swiper,
    Title,
    GoodsList,
    GoodsGroup,
    Breadcrumbs,
    Picture,
    Store
  },

  setup(_, { root }) {
    const store = root.$store
    const route = root.$route
    const router = root.$router
    const $t = root.$jst

    const hostname = window.location.host
    console.log('hostname', hostname)

    const frameData = ref([])
    const swipeRefs = ref(null)
    // const refreshFunc = computed(() => store.state.app.refreshModelFunc)

    const id = computed(() => root.$route.query.id)

    const data = reactive({
      // 框架数组
      frameData: computed({
        get() {
          if (root.$route.query.id) {
            return frameData.value
          } else {
            return frameData.value // store.state.app.template
          }
        },
        set(value) {
          frameData.value = value
        }
      }),

      firstData: null,

      // 填充数据的数组
      modelData: {
        2: {},
        3: {},
        4: {},
        5: {},
        6: {},
        7: {},

        11: {}
      },

      model: '',

      filteredComponent: {},

      breadcrumbList: [{ name: $t('Goodsall.homepage'), value: '' }],

      pageTitleData: {},

      currencyId: computed(() => store.state.app.currencyItem?.id),
      countryId: computed(() => store.state.app.countryItem?.id),

      clientWidth: document.documentElement.clientWidth,
      hostname: window.location.host

    })

    // const { fetch: getStoreByDomain } = useRequest('home/getInfoByDomain', {
    //   data: computed(() => ({
    //     domainPrefix: data.hostname
    //   })),

    //   immediate: false,
    //   onSuccess:(res) =>{

    //     console.log(res,'res')
    //   }
    // })
    // getStoreByDomain()

    watch(() => data.currencyId, res => {
      if (data.currencyId && data.countryId) {
        getHomeDataFetch()
        getGoodsList()
        getGoodsGroupList()
      }
    })

    watch(() => data.countryId, _ => {
      if (data.currencyId && data.countryId) {
        getHomeDataFetch()
        getGoodsList()
        getGoodsGroupList()
      }
    })

    // 获取首页框架
    const { fetch: getHomeDataFetch } = useRequest('home/getHomeData', {
      data: computed(() => ({
        // 如果是聚合站就传 1
        type: getDomainPrefix === '' ? '1' : '2',
        templateId: root.$route.query.id,
        domainPrefix: getDomainPrefix
      })),

      initialData: toRef(data, 'frameData'),
      immediate: false,
      isErrorMessage: false
    })

    watch(() => root.$route, to => {
      if (to.name === 'Home') return
      // if (to.query.id) {
      //   getHomeDataFetch()
      // } else {
      //   refreshFunc.value()
      // }
    })

    const { data: getAdvertisementResult, fetch: getAdvertisementList } = useRequest('home/getAdvertisementList', {
      immediate: false,
      initialData: toRef(data.modelData, '2')
    })

    const { data: getNavListresult, fetch: getNavList } = useRequest('home/getNavList', {
      immediate: false,
      initialData: toRef(data.modelData, '3')
    })
    const { data: getTitleListResult, fetch: getTitleList } = useRequest('home/getTitleList', {
      immediate: false,
      initialData: toRef(data.modelData, '5')
    })

    const { fetch: getNoticeList } = useRequest('home/getNoticeList', {
      immediate: false,
      initialData: toRef(data.modelData, '4')
    })
    const { fetch: getGoodsList } = useRequest('home/getGoodsList', {
      data: computed(() => ({
        countryId: data.countryId,
        currencyId: data.currencyId
      })),
      immediate: false,
      initialData: toRef(data.modelData, '6')
    })

    const { fetch: getGoodsGroupList } = useRequest('home/getGoodsGroupList', {
      data: computed(() => ({
        countryId: data.countryId,
        currencyId: data.currencyId
      })),
      immediate: false,
      initialData: toRef(data.modelData, '7')
    })

    const { fetch: getStoreListData } = useRequest('home/getStoreListData', {
      immediate: false,
      initialData: toRef(data.modelData, '11')
    })

    const requestObj = {
      2: getAdvertisementList,
      3: getNavList,
      4: getNoticeList,
      5: getTitleList,
      6: getGoodsList,
      7: getGoodsGroupList,

      11: getStoreListData
    }

    // 后端没有返回 modelType，所以在前端做了标记
    watch(() => getTitleListResult.value, res => {
      const result = Object.entries(res).find(([key, current = []]) => {
        current.forEach(inner => {
          Object.assign(inner, { modelType: '5' })
        })

        const found = data.frameData.find(inner => +inner.id === +key && inner.sonType === 5)

        if (found) {
          return current
        }
      })

      if (result) {
        store.commit('app/SET_FOOTER_DATA', result[1][0])
      }
    })

    watch(() => getNavListresult.value, res => {
      Object.values(res).forEach((current = []) => {
        current.forEach(inner => {
          Object.assign(inner, { modelType: '3' })
        })
      })
    })

    watch(() => getAdvertisementResult.value, res => {
      const result = Object.entries(res).find(([key, value]) => {
        const found = data.frameData.find(current => +current.id === +key && current.sonType === 3)

        if (found) {
          return value
        }
      })

      if (result) {
        store.commit('app/SET_HEADER_DATA', result[1][0])
      }
    })

    // 是否第一个组件为 swiper
    const getFilteredComponent = () => {
      const filter = data.frameData.filter(current => requestObj[current.modelType])

      // if (filter[0] && filter[0].modelType === 2 && filter[0].showMode === 6) {
      //   data.firstData = filter[0] || null
      //   filter.splice(0, 1)
      // }

      return filter
    }

    const getModelData = () => {
      // 映射且去重加调用
      [...new Set(data.frameData.map(current => current.modelType))]
        .map(current => requestObj[current])
        .filter(current => current && current())

      data.filteredComponent = getFilteredComponent()
    }

    watch(() => store.state.app.template, () => {
      getModelData()
    })

    watch(() => data.frameData, () => {
      getModelData()
    })

    const { data: getTopicPageTitleResult, fetch: getTopicPageTitleFetch } = useRequest('home/getTopicPageTitle', {
      data: {
        id: route.query.id
      },
      initialData: toRef(data, 'pageTitleData'),

      immediate: false
    })

    watch(() => getTopicPageTitleResult.value, res => {
      data.breadcrumbList.push({
        name: res.title,
        value: res.id
      })
    })

    const onClickBreadcrumb = (item) => {
      if (!item.length) {
        router.push({ path: '/' })
      }
    }

    onMounted(() => {
      if (!(data.countryId && data.currencyId)) return

      if (root.$route.query.id) {
        getHomeDataFetch()
        getTopicPageTitleFetch()
      } else {
        getHomeDataFetch()
        // getTopicPageTitleFetch()
        // refreshFunc.value()
      }
    })

    useCodeInLifeCycle(() => {
      const resizeHandler = _ => {
        const clientWidth = document.documentElement.clientWidth

        if (clientWidth === data.clientWidth) return

        data.clientWidth = clientWidth
        swipeRefs.value.forEach(current => current.resize())
      }

      const debounced = useDebounce(resizeHandler)

      window.addEventListener('resize', debounced, false)

      return () => {
        window.removeEventListener('resize', debounced)
      }
    })

    const onClickNavigation = item => {
      handleLineClick(item)
    }

    return {
      ...toRefs(data),

      id,
      swipeRefs,

      onClickBreadcrumb,
      onClickNavigation
    }
  }
}
</script>

<style lang="scss" scoped>
.home__container {
  padding: 0 0 30px;
}

.home__width-container {
  // width: 1200px;
  // max-width: 1200px;
  // margin: 0 auto 0;
  // min-width: 1200px;
  width: 100%;
  // margin-top: 20px;
  // max-width: 1600px;
}

.home__section {
  margin: 30px 0;
}

.kingkong-row {
  display: flex;
  flex-wrap: wrap;
  padding: 38px 0 30px;

  & + .kingkong-row {
    padding-top: 13px;
  }

  .col {

    flex-shrink: 0;

    &.type-4 {
      width: 25%;
      flex-basis: 25%;
    }

    &.type-5 {
      width: 20%;
      flex-basis: 20%;
    }
  }
}

.kingkong-ball {
  display: block;
  margin-bottom: 20px;

  .icon {
    text-align: center;

    .img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .name {
    margin-top: 18px;
    font-size: 16px;
    text-align: center;
    color: #222222;
  }
}
</style>
