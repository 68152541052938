<!--
 * @Author: Neko
 * @Date: 2021-03-17 15:43:47
 * @LastEditTime: 2021-03-25 17:57:56
 * @LastEditors: Neko
-->
<template>
  <div class="shop__container">
    <div class="shop__wrap">
      <div class="shop__header">
        <h4 class="title">{{ $t('home.store.recommend_store') }}</h4>
      </div>

      <div class="shop__item">
        <template v-if="data.length">
          <a
            v-for="item in data"
            :key="item.id"
            class="shop-item__container"
            href="javascript:void(0)"
            @click="onClickItemBtn(item)"
          >
            <div class="shop__logo-wrap">
              <img v-if="item.logoUrl" :src="item.logoUrl">
              <img v-else src="@/assets/images/shop-empty.png">
            </div>

            <div class="shop__name">
              {{ item.storeName || $t('home.store.select_store') }}
            </div>
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import { handleLineClick } from '@/utils'

export default defineComponent({
  name: 'Store',

  props: {
    data: {
      type: Array,
      default: () => ([])
    },

    modelType: {
      type: [String, Number],
      default: ''
    }
  },

  setup(props) {
    const data = reactive({
      host: process.env.VUE_APP_HOST_URL
    })

    const onClickItemBtn = item => {
      const data = {
        ...item,
        modelType: props.modelType
      }

      handleLineClick(data)
    }

    return {
      ...toRefs(data),
      onClickItemBtn
    }
  }
})
</script>

<style lang="scss" scoped>
.shop__container {
  padding: 0 12px;
  margin: 20px 0;

  .shop__wrap {
    width: 1200px;
    margin: 0 auto;
  }
}

.shop__header {
  padding: 15px 21px;
  margin-bottom: 14px;

  .title {
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    color: #0049AC;
  }
}

.shop__item {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.shop-item__container {
  display: block;
  width: 220px;
  padding: 16px 30px;
  border-radius: 8px;
  margin-right: 25px;
  margin-bottom: 20px;
  background: #fff;

  &:nth-of-type(5n + 5) {
    margin-right: 0;
  }

  &.type-2, &.type-3 {
    width: 220px;
    min-height: 220px;

    .shop__logo-wrap {
      width: 220px;
      min-height: 220px;
      // height: 55px;
    }
  }

  .shop__logo-wrap {
    width: 160px;
    min-height: 160px;
    // height: 100px;
    overflow: hidden;
    border-radius: 8px;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .shop__name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    font-size: 15px;
    line-height: 17px;
    margin-top: 8px;
    text-align: center;
    color: #222222;
  }
}
</style>
