<!--
 * @Author: Neko
 * @Date: 2021-03-16 17:12:00
 * @LastEditTime: 2021-03-26 14:09:36
 * @LastEditors: Neko
-->
<template>
  <div class="picture__container">
    <div class="picture__wrap">
      <template v-if="data.length">
        <div
          v-for="item in data"
          :key="getImageUrl(item)"
          class="picture__content"
          :class="{
            ['type-' + (type - 1)]: true
          }"
          @click="onClickItemBtn(item)"
        >
          <img :src="getImageUrl(item)">
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import { handleLineClick } from '@/utils'

export default defineComponent({
  name: 'PictureControl',

  props: {
    type: {
      type: [String, Number],
      default: ''
    },

    data: {
      type: Array,
      default: () => ([])
    },

    modelType: {
      type: [String, Number],
      default: ''
    }
  },

  setup(props) {
    const propsRef = toRefs(props)

    const data = reactive({

    })

    const onClickItemBtn = item => {
      const data = {
        ...item,
        modelType: propsRef.modelType.value
      }
      handleLineClick(data)
    }

    const getImageUrl = item => {
      return item.imageUrl
    }

    return {
      ...toRefs(data),

      getImageUrl,
      onClickItemBtn
    }
  }
})
</script>

<style lang="scss" scoped>
.picture__container {
  padding: 14px 0 26px;

  .picture__wrap {
    width: 1200px;
    margin: 0 auto;
  }
}

.picture__wrap {
  display: flex;
  flex-wrap: wrap;
  min-width: 1200px;
  width: 1200px;
  // padding: 0 7px;
  margin: 0 auto;

  .picture__content {
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
    overflow: hidden;
    background: #f5f5f5;
    box-shadow: 0px 4px 8px 0px rgba(232, 232, 234, 0.95);

    &.type-1 {
      width: 100%;
      flex-shrink: 0;
    }

    &.type-2 {
      width: 584px;
      flex-shrink: 0;
      margin-right: 16px;

      &:nth-of-type(2n + 2) {
        margin-right: 0;
      }
    }

    &.type-3 {
      width: 376px;
      flex-shrink: 0;
      margin-right: 29px;

      &:nth-of-type(3n + 3) {
        margin-right: 0;
      }
    }

    img {
      width: 100%;
    }
  }
}
</style>
