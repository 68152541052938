<!--
 * @Author: Neko
 * @Date: 2021-01-05 16:23:59
 * @LastEditTime: 2021-03-26 14:08:36
 * @LastEditors: Neko
-->
<template>
  <div class="swiper__container">
    <div class="swiper__content">
      <el-carousel
        ref="carousel"
        :height="imageHeight[active] + 'px'"
        :interval="(4) * 1000"
        arrow="never"
        indicator-position="none"
        @change="onChangeCarousel"
      >
        <template v-if="data.length">
          <el-carousel-item
            v-for="(item, index) in data"
            :key="getImageUrl(item)"
          >
            <div
              class="swiper__image"
              :style="{
                'background-image': 'url(' + getImageUrl(item) + ')',
                height: imageHeight[index] + 'px'
              }"
              @mouseenter="onMouseEnter"
              @mouseleave="onStartCountdown"
              @click="onClickItemBtn(item)"
            />
          </el-carousel-item>
        </template>
      </el-carousel>

      <div class="swiper__indicator">
        <div class="step">
          <span class="time">{{ active + 1 }}</span>
          <div class="progress">
            <span class="bar" :style="{ width: ((process / ((4) * 1000)) * 100) + '%' }" />
          </div>
          <span class="time">{{ data.length }}</span>
        </div>

        <div class="arrow">
          <i class="icon el-icon-back" @click.stop="onClickChangeBtn(-1)" />

          <i class="icon el-icon-right" @click.stop="onClickChangeBtn(1)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleLineClick } from '@/utils'
import { ref, toRefs, reactive, onMounted, onBeforeUnmount, watch } from '@vue/composition-api'

export default {
  name: 'Swiper',

  props: {
    type: {
      type: [String, Number],
      default: ''
    },

    height: {
      type: String,
      default: '602px'
    },

    data: {
      type: Array,
      default: () => ([])
    },

    autoplay: {
      type: Boolean,
      default: true
    },

    modelType: {
      type: [String, Number],
      default: ''
    },

    showNum: {
      type: Number,
      default: 4
    },

    noMargin: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { root }) {
    const propsRef = toRefs(props)
    const carousel = ref(null)

    const data = reactive({
      imageHeight: [],

      active: 0,
      process: 4 * 1000,
      timer: null,

      isClearProcess: false
    })

    watch(() => propsRef.data.value, res => {
      handleImageLoadAction(getImageUrl(res[0]), 0)
    })

    const onClickItemBtn = row => {
      const data = {
        ...row,
        modelType: propsRef.modelType.value
      }
      handleLineClick(data)
    }

    const onStartCountdown = () => {
      if (propsRef.data.value.length < 2) {
        data.process = 0
        return
      }

      data.timer = window.setInterval(() => {
        if (data.process >= (4) * 1000) {
          data.process = 0
        } else {
          data.process += 50
        }
      }, 50)
    }

    const onMouseEnter = () => {
      data.process = (4) * 1000
      onEndCountdown()
    }

    const onEndCountdown = () => {
      window.clearInterval(data.timer)
    }

    const onClickChangeBtn = (direction) => {
      const ref = carousel.value
      if (direction === -1) {
        ref.prev()
      }

      if (direction === 1) {
        ref.next()
      }
      data.isClearProcess = true
    }

    const getImageUrl = (item) => {
      return item.imageUrl
    }

    const handleImageLoadAction = async(item, index) => {
      const image = new Image()
      image.onload = () => {
        const height = image.height
        const width = image.width

        const imageHeight = height * (Number.parseInt(document.documentElement.clientWidth)) / width

        root.$set(data.imageHeight, index, imageHeight)

        image.onload = null
      }
      image.src = item
    }

    const onChangeCarousel = (newIndex) => {
      data.active = newIndex
      // 因为计时器不精准的原因，所以使用了事件重置计时
      // FIX ELEMENT 的怪异行为，调用 next 和 prev 方法不会重置内部的自动播放定时器
      if (!data.isClearProcess) {
        data.process = 0
        data.isClearProcess = false
      }
      handleImageLoadAction(getImageUrl(propsRef.data.value[newIndex]), newIndex)
    }

    const resize = _ => {
      onChangeCarousel(data.active)
    }

    onMounted(() => {
      onStartCountdown()
    })

    onBeforeUnmount(() => {
      onEndCountdown()
    })

    return {
      ...toRefs(data),
      carousel,
      getImageUrl,

      resize,

      onStartCountdown,
      onClickItemBtn,
      onMouseEnter,
      onClickChangeBtn,
      onChangeCarousel
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper__container {
  margin-bottom: 20px;
  background: #fff;
}

.swiper__image {
  border-radius: 8px;
  overflow: hidden;
  min-height: 200px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
}

.swiper__indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 0 51px;

  .step {
    display: flex;
    align-items: center;

    .time {
      font-size: 14px;
      font-weight: bolder;
      color: #0049AC;

      &:first-child {
        margin-right: 20px;
      }

      &:last-child {
        margin-left: 20px;
      }
    }

    .progress {
      display: inline-block;
      width: 120px;
      height: 2px;
      border-radius: 5px;
      background: #EEEEEE;

      .bar {
        display: block;
        width: 100%;
        height: 2px;
        // transition: .5s all;
        background: #0049AC;
      }
    }
  }

  .arrow {
    margin-left: 120px;

    .icon {
      font-size: 16px;
      font-weight: bolder;

      &:last-child {
        margin-left: 40px;
      }
    }
  }
}
</style>
